import styled, { css } from 'styled-components';
import { ButtonArrowProps } from './ButtonArrow';

export const ButtonElement = styled.button.withConfig({
  shouldForwardProp: (prop) => !['appearence', 'direction', 'mobile'].includes(prop),
})<ButtonArrowProps>`
  ${({ theme, appearence, direction, mobile }) => {
    return css`
      ${mobile === false &&
      css`
        @media screen and (max-width: ${theme.breakpoint.sm}) {
          display: none !important;
        }
      `}

      ${appearence === 'square' &&
      css`
        min-width: calc(${theme.spacing.base} * 3);
        min-height: 80px;
      `};

      background-color: ${theme.color.white};

      ${(direction === 'right' &&
        css`
          border-top-left-radius: ${theme.radius.sm};
          border-bottom-left-radius: ${theme.radius.sm};
          background-image: url('data:image/svg+xml;utf8,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.7075 16.7075L18.7075 25.7075C18.5199 25.8951 18.2654 26.0006 18 26.0006C17.7346 26.0006 17.4801 25.8951 17.2925 25.7075C17.1049 25.5199 16.9994 25.2654 16.9994 25C16.9994 24.7346 17.1049 24.4801 17.2925 24.2925L24.5863 17H5C4.73478 17 4.48043 16.8947 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16C4 15.7348 4.10536 15.4804 4.29289 15.2929C4.48043 15.1054 4.73478 15 5 15H24.5863L17.2925 7.70751C17.1049 7.51987 16.9994 7.26537 16.9994 7.00001C16.9994 6.73464 17.1049 6.48015 17.2925 6.29251C17.4801 6.10487 17.7346 5.99945 18 5.99945C18.2654 5.99945 18.5199 6.10487 18.7075 6.29251L27.7075 15.2925C27.8005 15.3854 27.8742 15.4957 27.9246 15.6171C27.9749 15.7385 28.0008 15.8686 28.0008 16C28.0008 16.1314 27.9749 16.2615 27.9246 16.3829C27.8742 16.5043 27.8005 16.6146 27.7075 16.7075Z" fill="black"/></svg>');
        `) ||
      css`
        border-top-right-radius: ${theme.radius.sm};
        border-bottom-right-radius: ${theme.radius.sm};
        background-image: url('data:image/svg+xml;utf8,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28.0001 16C28.0001 16.2652 27.8947 16.5196 27.7072 16.7071C27.5196 16.8947 27.2653 17 27.0001 17H7.4138L14.7076 24.2925C14.8005 24.3854 14.8742 24.4957 14.9244 24.6171C14.9747 24.7385 15.0006 24.8686 15.0006 25C15.0006 25.1314 14.9747 25.2615 14.9244 25.3829C14.8742 25.5043 14.8005 25.6146 14.7076 25.7075C14.6146 25.8004 14.5043 25.8741 14.383 25.9244C14.2616 25.9747 14.1314 26.0006 14.0001 26.0006C13.8687 26.0006 13.7386 25.9747 13.6172 25.9244C13.4958 25.8741 13.3855 25.8004 13.2926 25.7075L4.29255 16.7075C4.19958 16.6146 4.12582 16.5043 4.07549 16.3829C4.02517 16.2615 3.99927 16.1314 3.99927 16C3.99927 15.8686 4.02517 15.7385 4.07549 15.6171C4.12582 15.4957 4.19958 15.3854 4.29255 15.2925L13.2926 6.29251C13.4802 6.10487 13.7347 5.99945 14.0001 5.99945C14.2654 5.99945 14.5199 6.10487 14.7076 6.29251C14.8952 6.48015 15.0006 6.73464 15.0006 7.00001C15.0006 7.26537 14.8952 7.51987 14.7076 7.70751L7.4138 15H27.0001C27.2653 15 27.5196 15.1054 27.7072 15.2929C27.8947 15.4804 28.0001 15.7348 28.0001 16Z" fill="black"/></svg>');
      `};
      background-repeat: no-repeat;
      background-position: center;

      box-shadow: ${theme.box_shadow.base};

      ${appearence === 'circle' &&
      css`
        min-width: 4rem;
        min-height: 4rem;
        border-radius: 100%;
      `};

      cursor: pointer;

      // -----------------------
      // Swiper Carrousel
      // -----------------------
      &.carrousel-prev,
      &.carrousel-next {
        position: absolute;
        top: calc(50% - 3rem);
        z-index: 10;
      }
      &.carrousel-prev {
        left: -2rem;
      }
      &.carrousel-next {
        right: -2rem;
      }
      // ----------------------

      // -----------------------
      // Swiper MediaGallery
      // -----------------------
      &.media-gallery-prev,
      &.media-gallery-next {
        position: absolute;
        top: calc(50% - 3rem);
        z-index: 10;
      }
      &.media-gallery-prev {
        left: 1rem;
      }
      &.media-gallery-next {
        right: 1rem;
      }
      // ----------------------

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    `;
  }};
`;
