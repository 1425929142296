import React, { MouseEvent, ReactNode } from 'react';
import { ButtonElement } from '@/components/button/ButtonArrow/ButtonArrow.styles';

export interface ButtonArrowProps {
  appearence?: 'square' | 'circle';
  direction: 'left' | 'right';
  mobile?: boolean;
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | undefined;
  disabled?: React.InputHTMLAttributes<HTMLInputElement>['disabled'];
}

const ButtonArrow = React.forwardRef<HTMLButtonElement, ButtonArrowProps>(
  ({ appearence = 'square', direction, mobile, children, ...props }: ButtonArrowProps, ref) => {
    return (
      <ButtonElement appearence={appearence} direction={direction} mobile={mobile} {...props}>
        {children}
      </ButtonElement>
    );
  }
);

ButtonArrow.displayName = 'ButtonArrow';

export default ButtonArrow;
