import styled, { css } from 'styled-components';

export const Element = styled.div`
  ${({ theme, ...props }) => {
    return css`
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${theme.spacing.base};

      text-align: center;
      color: ${theme.color.white};
      margin: 0 auto ${theme.spacing.lg} auto;
      padding: ${theme.spacing.sm};

      transition: all 0.4s ease-out;
      z-index: 200;

      > h1 {
        font-size: ${theme.typography.font_size.title_2xl};
      }

      > p {
        font-size: ${theme.typography.font_size.title_md};
      }

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        max-width: 50rem;

        > h1 {
          font-size: ${theme.typography.font_size.title_4xl};
        }

        > p {
          font-size: ${theme.typography.font_size.title_lg};
        }

        > button {
          //
        }
      }
    `;
  }};
`;
