import React, { ReactNode } from 'react';
import { Element } from './HeroTitle.styles';
import { Title } from '@/components/typography';
import Text from '@/components/typography/Text/Text';
import Button from '@/components/button/Button/Button';

export interface HeroTitleProps {
  active?: boolean;
  banner: BannerType;
  className?: string;
  children?: ReactNode;
}

const HeroTitle = ({ active, banner, children, ...props }: HeroTitleProps) => {
  return (
    <Element>
      <Title as="h1" weight="black">
        {banner?.title}
      </Title>
      {banner?.description && <Text>{banner?.description}</Text>}
      {banner?.url && (
        <Button appearance="outline" tabIndex={active ? 1 : -1} href={banner.url}>
          Saiba Mais
        </Button>
      )}
    </Element>
  );
};

export default HeroTitle;
