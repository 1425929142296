import React, { ReactNode } from 'react';
import AppContext, { AppContextType } from '@/context/AppContext';

type AppProviderProps = AppContextType & {
  children?: ReactNode;
};

const AppProvider = ({ children, ...props }: AppProviderProps) => {
  return <AppContext.Provider value={{ ...props }}>{children}</AppContext.Provider>;
};

export default AppProvider;
