import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import HeroTitle from '@/components/banner/HeroTitle/HeroTitle';
import {
  CarrouselBulletsContainer,
  CarrouselContainer,
  CarrouselItem,
  CarrouselWrapper,
  CarrrouselSection,
} from './BannerHero.styles';
import ButtonArrow from '@/components/button/ButtonArrow/ButtonArrow';
import ButtonFlatBullet from '@/components/button/ButtonFlatBullet/ButtonFlatBullet';
import Image from '@/providers/Image';

export interface BannerHeroProps {
  banners: [BannerType];
  className?: string;
  children?: ReactNode;
}

const ROTATE_BANNER_INTERVAL = 5000;

const BannerHero = ({ banners, children, ...props }: BannerHeroProps) => {
  const [carrousel, setCarrousel] = useState(0);
  const next = useCallback(() => {
    carrousel === banners.length - 1 ? setCarrousel(0) : setCarrousel((prevState) => prevState + 1);
  }, [carrousel, banners]);

  const prev = useCallback(() => {
    if (carrousel === 0) {
      setCarrousel(banners.length - 1);
    } else {
      setCarrousel((prevState) => prevState - 1);
    }
  }, [carrousel, banners.length]);

  useEffect(() => {
    const timer = setInterval(() => {
      next();
    }, ROTATE_BANNER_INTERVAL);
    return () => {
      clearInterval(timer);
    };
  }, [carrousel, next]);

  return (
    <div>
      <CarrrouselSection>
        <CarrouselContainer>
          <CarrouselWrapper role="presentation">
            {banners.map((banner, index) => (
              <CarrouselItem
                key={banner.id}
                className={cx({ active: carrousel === index })}
                style={{
                  order: carrousel === index ? '1' : '2',
                }}
                tabIndex={-1}
              >
                <Image
                  src={`${banner.image.imageUrl}`}
                  placeholder={`data:image/jpeg;base64,${banner.image.blurDataUrl}`}
                  fadeInOnBlur
                  alt={banner.title || banner.button_label}
                  fill
                  priority={index === 0}
                />
                <HeroTitle banner={banner} active={carrousel === index} />
              </CarrouselItem>
            ))}
          </CarrouselWrapper>
          <ButtonArrow direction="left" onClick={prev} />
          <ButtonArrow direction="right" onClick={next} />
          <CarrouselBulletsContainer>
            {banners.map((item, index) => (
              <ButtonFlatBullet
                color="white"
                onClick={() => {
                  carrousel !== index && setCarrousel(index);
                }}
                aria-disabled={carrousel === index}
                key={item.id}
                aria-current={carrousel === index}
              ></ButtonFlatBullet>
            ))}
          </CarrouselBulletsContainer>
        </CarrouselContainer>
      </CarrrouselSection>
    </div>
  );
};

export default BannerHero;
