import React from 'react';
import Head from 'next/head';
import { FACEBOOK_APP_ID } from '@/config';

export interface HeadMetaTagProps {
  meta: Metadata;
}

const HeadMetaTag = ({ meta, ...props }: HeadMetaTagProps) => {
  const { title, description, canonical, opengraph, robots } = meta;
  return (
    <Head>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {robots === 0 && <meta name="robots" content="noindex,nofollow" />}

      {FACEBOOK_APP_ID && <meta property="fb:app_id" content={FACEBOOK_APP_ID} />}
      {(opengraph && (
        <>
          <meta property="og:locale" content="pt_BR" />
          <meta property="og:url" content={opengraph.url || canonical || ''} />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Kangaroo Tours" />
          <meta property="og:title" content={opengraph?.title || title} />
          <meta property="og:description" content={opengraph?.description || description || ''} />

          {opengraph.image?.url && <meta property="og:image" content={opengraph?.image.url} />}
          <meta property="og:image:type" content="image/jpg" />
          <meta property="og:image" content="https://assets-cdn.kangaroo.com.br/assets/images/open-graph-logo.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image" content="https://assets-cdn.kangaroo.com.br/assets/images/open-graph-icon.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@kangarootours" />
          <meta name="twitter:description" content={opengraph?.description || description || ''} />
          <meta name="twitter:title" content={opengraph?.title || title} />
          <meta name="twitter:image:src" content={opengraph?.image.url} />
        </>
      )) ||
        null}

      <link rel="canonical" href={canonical} />
      <link rel="dns-prefetch" href="//assets-cdn.kangaroo.com.br" />
      <link rel="dns-prefetch" href="//www.google-analytics.com" />
      <link rel="dns-prefetch" href="//www.googletagmanager.com" />
      {/*<link rel="dns-prefetch" href="//fonts.googleapis.com" />*/}
      {/*<link rel="dns-prefetch" href="//fonts.gstatic.com" />*/}
      <link rel="preconnect" href="//assets-cdn.kangaroo.com.br" />
      <link rel="preconnect" href="//www.google-analytics.com" />
      <link rel="preconnect" href="//www.googletagmanager.com" />
      {/*<link rel="preconnect" href="//fonts.googleapis.com" />*/}
      {/*<link rel="preconnect" href="//fonts.gstatic.com" />*/}
    </Head>
  );
};

export default HeadMetaTag;
