import React from 'react';
import NextImage, { ImageProps } from 'next/image';
import { ASSETS_CDN_URL } from '@/config';

type ImageCustomProps = {
  ref?: any;
  handleOnCompleted?: () => void;
  fadeInOnBlur?: boolean;
} & ImageProps;

const Image = React.forwardRef<HTMLImageElement, ImageCustomProps>(
  ({ fadeInOnBlur, src, priority = false, handleOnCompleted, ...props }, ref) => {
    let srcURL = typeof src === 'string' ? src.replace(/^\//, '') : src;

    if (typeof srcURL === 'string') {
      if (
        srcURL.startsWith('https://img.youtube') ||
        /https?:\/\/assets-cdn(-local|-stage)?.kangaroo.com.br/.test(srcURL)
      ) {
        srcURL = src;
      } else {
        if (srcURL.startsWith('data:')) {
          srcURL = src;
        } else {
          srcURL = ASSETS_CDN_URL ? `${ASSETS_CDN_URL}/${srcURL}` : `https://assets-cdn.kangaroo.com.br/${srcURL}`;
        }
      }
    }
    function handleOnLoadCompleted(img: HTMLImageElement) {
      if (!fadeInOnBlur) return;

      if (img.classList.contains('img-loading')) {
        img.classList.remove('img-loading');
      }

      if (img.classList.contains('img-pending')) {
        img.classList.remove('img-pending');
      }

      if (!img.classList.contains('img-loaded')) {
        img.classList.add('img-loaded');
        if (typeof handleOnCompleted === 'function') {
          handleOnCompleted();
        }
      }
    }

    return (
      <NextImage
        ref={ref}
        src={srcURL}
        priority={priority}
        onLoadingComplete={handleOnLoadCompleted}
        onLoad={(e) => {
          //
        }}
        className={fadeInOnBlur ? 'img-loading' : ''}
        {...props}
        unoptimized // FIXME: This might hurt PageSpeed, but improve the quality of the image for now, should be reviewed or disabled
      />
    );
  }
);

Image.displayName = 'Image';

export default Image;
