import React, { MouseEvent, ReactNode } from 'react';
import { Element } from '@/components/button/ButtonFlatBullet/ButtonFlatBullet.styles';
import { DefaultTheme } from 'styled-components';

export interface ButtonFlatBulletProps {
  readonly color?: keyof DefaultTheme['color'] & string;
  readonly active?: boolean;
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | undefined;
}

const ButtonFlatBullet = ({ children, ...props }: ButtonFlatBulletProps) => {
  return (
    <Element role="button" {...props}>
      {children}
    </Element>
  );
};

export default ButtonFlatBullet;
