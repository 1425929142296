import styled, { css } from 'styled-components';
import { ButtonFlatBulletProps } from './ButtonFlatBullet';

export const baseFlatBulletCss = css<ButtonFlatBulletProps>`
  ${({ theme, active, color, ...props }) => {
    return css`
      --element-color: ${(color &&
        css`
          ${theme.color[color]}
        `) ||
      css`
        ${theme.color.secondary_200}
      `};
      display: block;
      width: ${theme.spacing.md};
      height: 0.75rem;
      @media screen and (min-width: ${theme.breakpoint.sm}) {
        height: 0.5rem;
      }
      border: 2px solid var(--element-color);
      border-radius: ${theme.radius.base};
      &[aria-current='true'] {
        background: var(--element-color);
      }
      &[aria-disabled='true'] {
        cursor: not-allowed;
      }

      ${active &&
      css`
        background: var(--element-color);
      `}

      transition: all 0.5s ease-in-out;
    `;
  }};
`;

export const Element = styled.span.withConfig({
  shouldForwardProp: (prop) => !['color', 'active'].includes(prop),
})<ButtonFlatBulletProps>`
  ${({ theme, active, color, ...props }) => {
    return css`
      ${baseFlatBulletCss};
    `;
  }};
`;
