import { css } from 'styled-components';

/**
 * Add this to the image container to make the image blurred effect
 * Sample:
 *   img {
 *      ${imageBlurredFadeIn};
 *   };
 * */
export const imageBlurredFadeIn = css`
  opacity: 0;
  scale: 1.01;
  transition: opacity 200ms ease-in-out, scale 0.35s;
  &.img-loaded {
    opacity: 0.8 !important;
    scale: 1;
  }
  // This must come later to make the transition effect work smoothly
  &[style*='background-image'] {
    opacity: 0.4;
  }
`;
