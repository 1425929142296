import styled, { css } from 'styled-components';
import { imageBlurredFadeIn } from '@/components/image/imageLoadedFadeIn.styles';

export const CarrrouselSection = styled.section`
  --banner-height: 70vh;
  position: relative;
  height: var(--banner-height);
`;

export const CarrouselContainer = styled.div`
  ${({ theme, ...props }) => {
    return css`
      position: relative;
      height: var(--banner-height);

      flex-direction: column;
      flex-wrap: wrap;
      align-content: space-around;
      justify-content: flex-end;
      align-items: flex-end;

      > button {
        display: none;
        @media screen and (min-width: ${theme.breakpoint.sm}) {
          display: block;
        }
        position: absolute;
        top: calc(50% - 40px);
        left: auto;
        right: 0;
        z-index: 260;
        &:first-of-type {
          left: 0;
          right: auto;
        }
      }
    `;
  }};
`;

export const CarrouselWrapper = styled.div`
  ${({ theme, ...props }) => {
    return css`
      position: relative;
      display: flex;
      overflow: hidden;
      transition: all 0.4s ease-out;

      > div {
        min-width: 100vw;
      }

      img {
        object-fit: cover;

        // Blur Effect
        ${imageBlurredFadeIn};
        // Rollback the opacity to 1 as it was before applying the interpolation above
        &.img-loaded {
          opacity: 1 !important;
        }
      }
    `;
  }};
`;

export const CarrouselItem = styled.div`
  ${({ theme, ...props }) => {
    return css`
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: var(--banner-height);

      transition: all 0.4s ease-out, opacity 1s ease-out;

      &:not(.active) {
        opacity: 0;
      }
      &.active {
        opacity: 1;
      }

      // Build an Overlay
      //&:before
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        // Adds the overlay
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.39) 0%, rgba(19, 19, 19, 0) 32.72%),
          linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)); /*, #b1916a;
        opacity: 0.3
      ;*/
      }
    `;
  }};
`;

export const CarrouselBulletsContainer = styled.div`
  ${({ theme, ...props }) => {
    return css`
      position: absolute;
      width: 100%;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding-bottom: ${theme.spacing.md};
    `;
  }};
`;
